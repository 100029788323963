/** @jsx jsx */
import { jsx } from "theme-ui";

interface ComponentProps {
  alt: string;
  src: string;
  customStyles?: any;
}

const Image = ({ alt, src, customStyles }: ComponentProps) => (
  <img
    src={src}
    alt={alt}
    sx={{
      objectFit: "cover",
      borderRadius: "20px",
      border: "6px solid black",
      width: "100%",
      ...customStyles,
    }}
  />
);

export default Image;
