/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from '@src/theme'
const Footer = ({}) => {
  return (
    <footer
      sx={{
        width: "100%",
        mt: rem(40)
      }}
    >
      <div
        sx={{
          width: "100%",
     textAlign: 'center',
        py: [rem(20), rem(40)]
        }}
      >
   Cardigan Comics © copyright 2021, Bernard Caleo, ABN 16 988 631 066
      </div>
    </footer>
  );
};

export default Footer;
