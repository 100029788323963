import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ location, lang, defaultTitle, type, metadata }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            hostname
            image
          }
        }
      }
    `
  );

  let pathname = location.pathname || "/";
  if (pathname.endsWith("/"))
    pathname = pathname.substring(0, pathname.length - 1);
  const canonicalUrl = `${site.siteMetadata.hostname}${pathname}/`;
  const description = metadata && metadata.metaDescription
    ? metadata.metaDescription
    : site.siteMetadata.description;
 
  const socialImageUrl = metadata && metadata.socialImage ? metadata.socialImage : site.siteMetadata.image ?  site.siteMetadata.image :  "";
  const title = metadata
    ? `${metadata.metaTitle || defaultTitle} | Cardigan Comics`
    : `${site.siteMetadata.title}`;


  const meta = [
    {
      name: "description",
      content: description,
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content: description,
    },
    {
      property: "og:type",
      content: type || "website",
    },
    {
      property: "og:url",
      content: canonicalUrl,
    },
    {
      property: "og:image",
      content: socialImageUrl,
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content: description,
    },
    {
      property: "twitter:image",
      content: socialImageUrl,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={meta}
      link={[
        {
          rel: "canonical",
          key: canonicalUrl,
          href: canonicalUrl,
        },
        {
          rel: "apple-touch-icon",
          sizes: "57x57",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "60x60",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "72x72",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "76x76",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "114x114",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "152x152",
          href: "",
        },
        {
          rel: "apple-touch-icon",
          sizes: "192x192",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "192x192",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "192x192",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "96x96",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "",
        },
        {
          rel: "manifest",
          href: "/icons/manifest.json",
        },
      ]}
    >
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: "en-AU",
};

export default SEO;
