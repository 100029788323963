/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import { Link } from "gatsby";
interface ComponentProps {
  text: string;
  copy: string;
  src: string;
  url: string;
}

const Image = ({ src, text, copy, url }: ComponentProps) => {
  return (
    <Link
      to={url}
      sx={{
        cursor: "pointer",
        position: "relative",
        display: "block",
        width: "100%",
        height: rem(250),
        p: {
          m: 0,
        },
        ".tile-copy": {
          display: "none",
        },
        "&:hover": {
          ".tile-heading": {
            display: "none",
          },
          img: {
            filter: "grayscale(100%)",
          },
          ".tile-copy": {
            color: "#f9f9f9",
            display: "block",
          },
        },
      }}
    >
      <img
        src={src}
        sx={{
          position: "absolute",
          objectFit: "cover",
          borderRadius: "20px",
          width: "100%",
          height: "100%",
        }}
      />
      <div
        sx={{
          background:
            "linear-gradient(0deg,rgba(0,0,0,.1) 0,transparent 50%,transparent)",
          borderRadius: "20px",
          color: "white",
          position: "relative",
          zIndex: 2,
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          pl: rem(20),
          pb: rem(20),
          fontSize: rem(30),
          pr: rem(20),
        }}
      >
        <p className="tile-heading">{text}</p>
        <p className="tile-copy">{copy}</p>
      </div>
    </Link>
  );
};
export default Image;
