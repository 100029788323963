/** @jsx jsx */
import { jsx } from "theme-ui";
import Container from "@components/Container";
import { Link } from "gatsby";
import { rem } from "@src/theme";
import { navTileUrl } from "@src/templates/home";

const Banner = () => (
  <div>
    <Link
      to="/"
      sx={{
        textDecoration: "none!important",
      }}
    >
      <h1
        sx={{
          fontSize: [rem(48), null, rem(80)],
          mb: rem(10),
          mt: [rem(40), null, rem(20)],
          fontFamily: "heading",
        }}
      >
        Cardigan Comics
      </h1>
    </Link>
    <p
      sx={{
        fontSize: rem(20),
        mt: rem(0),
        mb: rem(40),
      }}
    >
      comic book teaching, tutoring, mentoring and performance
    </p>

    <hr
      sx={{
        width: "100%",
        height: "1px",
        bg: "lightGreen",
        border: "none",
      }}
    />
  </div>
);

const Nav = () => (
  <nav>
    <ul
      sx={{
        display: "flex",
        flexWrap: "wrap",
        pt: rem(30),
        pb: rem(15),
        li: {
          mr: [rem(20), null, rem(35)],
          fontSize: [rem(20), null, rem(22)],
        },
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
    >
      {Object.keys(navTileUrl).map((key) => (
        <li>
          <Link to={`/` + navTileUrl[key]}>{key}</Link>
        </li>
      ))}
    </ul>
  </nav>
);

export const DefaultHeader = () => {
  return (
    <div>
      <Container
        customStyles={{
          width: ["95%", null, "90%"],
        }}
      >
        <Banner />
        <Nav />
      </Container>
    </div>
  );
};

const Main = () => {
  return <DefaultHeader />;
};
export default Main;
