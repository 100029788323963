/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

import Container from "@components/Container";
import { rem } from "@src/theme";
import Layout from "@src/components/Layout";
import SEO from "@components/seo";
import { Grid, Cell } from "@components/Grid";
import Image from "@components/Image";
import NavTile from "@components/NavTile";

const navItems = {
  "Comics teaching": "images/schoollibrarycolour.jpg",
  "Comics tutoring": "images/tutoringcolour.jpg",
  "Comics mentoring": "images/mentoringcolour.jpg",
  "Comics performance": "images/performancecolour.jpg",
  About: "images/aboutcolour.jpg",
  "Our comics": "images/comic-bunch.jpg",
  Contact: "images/contactcolour.jpg",
};
const navTileCopy = {
  "Comics teaching":
    "Engaging workshops and insights into the comic book artform",
  "Comics tutoring": "Private lessons for beginning comics artists of all ages",
  "Comics mentoring": "An expert eye to help refine your comics masterpiece",
  "Comics performance":
    "Comics come to life through the Japanese art of kamishibai",
  About: "All about Bernard Caleo, the guy in the cardigan",
  "Our comics": "Bernard’s comic books, online and in print",
  Contact: "Always keen for a comics chat",
};
export const navTileUrl = {
  "Comics teaching": "comics-teaching",
  "Comics tutoring": "comics-tutoring",
  "Comics mentoring": "comics-mentoring",
  "Comics performance": "comics-performance",
  "Our comics": "our-comics",
  About: "about",
  Contact: "contact",
};

export default (props) => {
  return (
    <Layout>
      <SEO
        defaultTitle="Home"
        metadata={{
          metaDataDescription:
            "Bernard Caleo started making comic books with friends in 1990. He created Cardigan Comics in 1997 to encourage more people to make comics. In 2021 he commenced doctorate research at the University of Melbourne investigating the presentation of real places by Australian comic books.",
        }}
        location={props.location}
      />
      <div
        sx={{
          pt: [rem(20), rem(40), rem(60)],
        }}
      >
        <Container>
          <section
            sx={{
              pb: rem(80),
              pt: rem(40),
            }}
          >
            <Grid>
              <Cell gridColumn={["span 12", "span 4"]}>
                <Image
                  src="/images/profile-shot.png"
                  alt="Profile shot of Bernard in front of comic sketches"
                />
              </Cell>
              <Cell gridColumn={["span 12", "span 6"]}>
                <div
                  sx={{
                    pt: [rem(10), null, rem(40)],
                  }}
                >
                  <h2
                    sx={{
                      fontSize: rem(60),
                    }}
                  >
                    Bernard Caleo
                  </h2>
                  <p>
                    Bernard has been teaching about comic books since the
                    twentieth century.
                    <br />
                    <br />
                    So if you want:
                  </p>

                  <ul>
                    <li>
                      — a fun comic book expert to come and speak to your
                      students about comics...
                    </li>
                    <li>
                      — someone to lead a fun comics workshop for your
                      students...
                    </li>
                    <li>— to book a comics tutor...</li>
                    <li>— help with writing and drawing your own comics...</li>
                    <li>— to watch a comic book performed live...</li>
                  </ul>

                  <p>...you’re in the right place.</p>
                </div>
              </Cell>
            </Grid>
          </section>
        </Container>

        <section
          sx={{
            bg: "tomato",
            py: rem(80),
          }}
        >
          <Container>
            <h2
              sx={{
                mt: 0,
              }}
            >
              See what Bernard has to offer
            </h2>

            <Grid gridRowGap={["48px"]}>
              {Object.keys(navItems).map((key) => (
                <Cell gridColumn={["span 5", null, "span 3"]}>
                  <NavTile
                    text={key}
                    src={navItems[key]}
                    copy={navTileCopy[key]}
                    url={navTileUrl[key]}
                  />
                </Cell>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  );
};
