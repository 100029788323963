/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import React from "react";

interface ComponentProps {
  children: React.ReactNode;
  customClass?: string;
  customStyles?: any;
}

const Container = ({ children, customClass, customStyles }: ComponentProps) => {
  return (
    <div
      className={`${customClass}`}
      sx={{
        maxWidth: rem(1060),
        width: ["90%"],
        mx: "auto",
        ...customStyles,
      }}
    >
      {children}
    </div>
  );
};

export default Container;
