/** @jsx jsx */
import { jsx } from "theme-ui";
import { GRID_GUTTERS } from "@src/theme";

interface GridComponentProps {
  gridTemplateColumns?: string[];
  gridRowGap?: string[];
  gridColumnGap?: string[];
  children: React.ReactNode;
  styles?: Object;
}
export const Grid = ({
  gridTemplateColumns,
  gridRowGap,
  gridColumnGap,
  styles,
  ...props
}: GridComponentProps) => {
  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: gridTemplateColumns || [
          "repeat(4, 1fr)",
          "repeat(10, 1fr)",
        ],
        gridColumnGap: gridColumnGap ? gridColumnGap : GRID_GUTTERS,
        gridRowGap: gridRowGap ? gridRowGap : [],
        ...styles,
      }}
      {...props}
    ></div>
  );
};

interface CellComponentProps {
  gridColumn?: string[];
  gridRow?: string[];
  styles?: Object;
  children?: React.ReactNode;
}

export const Cell = ({
  gridColumn,
  gridRow,
  styles,
  children,
}: CellComponentProps) => (
  <div
    sx={{
      gridColumn: gridColumn || ["span 4", "span 10"],
      gridRow: gridRow || ["initial"],
      ...styles,
    }}
  >
    {children}
  </div>
);
