/** @jsx jsx */
import { jsx } from "theme-ui";
import Header from "@components/Header";
import Footer from "@components/Footer";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css"; //this will auto show and hide the link on focus
import { rem } from "@src/theme";
import "../../fonts/fonts.css";
import { GlobalStyle } from "../../";
interface ComponentProps {
  children: React.ReactNode;
  sticky?: Boolean;
  title?: string;
}
export const Layout = ({ children }: ComponentProps) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <SkipNavLink />
      <GlobalStyle />
      <Header />
      <SkipNavContent />
      <main
        id="content"
        sx={{
          width: "100%",
          flex: "1 1 auto",
          mx: "auto",
        }}
      >
        <div
          sx={{
            width: "100%",
          }}
        >
          {children}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
